<template>
  <v-card :loading="loading" :elevation="mdScreen ? 0: 2" class="create-post-container mb-12 mb-md-0 fill-height">
    <v-tabs v-model="editTabs">
      <v-tab>Basic</v-tab>
      <v-tab>Optional</v-tab>
    </v-tabs>
    <v-divider class="tabber"></v-divider>
    <v-form ref='updateProjectForm' v-model='valid' lazy-validation @submit.prevent='validate()' id='update-project-form' class='v-text-field--rounded form' loading>
      <v-card-text>
        <v-tabs-items v-model="editTabs">
          <v-tab-item>
            <v-row>
              <v-col>
                <v-textarea ref='title' outlined required auto-grow no-resize
                            :row-height="2"
                            :rules="titleRules"
                            hide-details
                            placeholder="Title of your Post"
                            class="textarea"
                            height="auto"
                            v-model='project.title'>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <editor @markup="handleMarkup" @markupJSON="handleMarkupJSON" v-model="project.content" class="editor-container" :class="{'error--text': descriptionValidator.length === 0}"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="$apollo.queries.categories.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  outlined
                  hide-selected
                  ref="category"
                  required
                  deletable-chips
                  :items="compCategories"
                  chips
                  :rules="categoryRules"
                  v-model="project.categories"
                  item-value="id"
                  item-text="name"
                  :loading="loading"
                  :menu-props="autocompleteMenuProps"
                  multiple
                  eager
                  label="Select Categories" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="tags pt-5 mb-5" v-if="$apollo.queries.tags.loading">
                  <v-card>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </v-card>
                </div>
                <div class="tags" v-else>
                  <h3 class="text--secondary font-weight-medium">Select Tag</h3>
                  <v-radio-group v-model="tag" :mandatory="true" row hide-details>
                    <v-radio hide-details class="mr-2 mb-3" :label="item.name" v-for="(item, i) in tags" :key="i" :value="item.id"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <div class="additional">
              <v-row>
                <v-col v-if="$apollo.queries.skills.loading">
                  <v-card>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </v-card>
                </v-col>
                <v-col v-else>
                  <v-autocomplete
                    outlined hide-no-data hide-selected persistent-hint small-chips multiple
                    ref="skills"
                    v-model="project.skills"
                    :items="skills"
                    :search-input.sync="searchInput"
                    @input="searchInput = null"
                    :loading="loading"
                    :rules="skillsRules"
                    :counter="10"
                    item-text="name"
                    item-value="id"
                    hint="⚡ Add relevant skills so that people can reach you faster!"
                    label="Skills"
                    :menu-props="autocompleteMenuProps"
                    placeholder="Start typing to fetch skills"
                    prepend-inner-icon="mdi-flask"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    outlined clearable persistent-hint hide-no-data auto-select-first
                    ref='location'
                    v-model="project.jobLocation"
                    :search-input.sync="locationInput"
                    @input="locationInput = null"
                    :items="items"
                    :loading="loading"
                    item-text="Description"
                    item-value="API"
                    label="Location"
                    placeholder="Start typing to fetch your location"
                    prepend-inner-icon="mdi-map-marker"
                    :menu-props="autocompleteMenuProps"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider v-if="!$vuetify.breakpoint.smAndDown"></v-divider>
      <v-footer :padless="true" :fixed="$vuetify.breakpoint.smAndDown">
        <v-card-text>
          <v-btn type='submit' color='secondary' depressed x-large :disabled="loading || !(project.title && descriptionValidator.length > 0 && project.categories.length > 0)" :loading='loading' block>
            <template v-slot:loader>
              <span>Updating...</span>
            </template>
            <span class="mr-1">Update</span>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-footer>
    </v-form>
  </v-card>
</template>
<script>
import Editor from '../Post/Editor'
import breakPoints from '../../mixins/breakPoints'
import autocomplete from '../../mixins/autocomplete'
import mapsMixin from '../../mixins/mapsMixin'
const CATEGORIES = require('../../graphql/queries/categories.graphql')
const UPDATE_PROJECT = require('../../graphql/updateProject.graphql')
const TAGS = require('../../graphql/queries/tags.graphql')
const SKILLS = require('../../graphql/queries/skills.graphql')
export default {
  apollo: {
    tags: {
      query: TAGS
    },
    categories: {
      query: CATEGORIES
    },
    skills: {
      query: SKILLS
    }
  },
  data () {
    return {
      valid: true,
      editTabs: null,
      description: {},
      loading: false,
      error: null,
      projectId: null,
      dialog: false,
      items: [this.project.jobLocation || ''],
      categories: [],
      searchInput: null,
      locationInput: null,
      titleRules: [v => (!!v) || 'Field is required'],
      skillsRules: [
        v => v.length < 11 || 'Cannot select more than 10 skills'
      ],
      categoryRules: [v => v.length > 0 || 'Field is required']
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    project: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    descriptionValidator () {
      return (this.description.content && this.description.content.filter(item => item.content)) || []
    },
    compCategories () {
      return this.categories.map(item => item.term)
    },
    tag: {
      get () {
        return this.project.projectTypes[0].id
      },
      set (val) {
        this.project.projectTypes[0].id = val
      }
    }
  },
  methods: {
    validate () {
      if (this.$refs.updateProjectForm.validate()) {
        this.submitPost()
      }
    },
    async submitPost () {
      this.loading = true
      const { title, content, id, jobLocation } = this.project
      let { categories, skills } = this.project
      categories = typeof this.project.categories[0] === 'string' ? this.project.categories : this.project.categories.map(category => category.id)
      skills = typeof this.project.skills[0] === 'string' ? this.project.skills : this.project.skills.map(skill => skill.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PROJECT,
          variables: {
            id: id,
            data: {
              title: title,
              description: content,
              categories: categories,
              skills: skills,
              location: jobLocation,
              project_type: this.tag
            }
          }
        })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
        this.$emit('updateDone')
      }
    },
    handleMarkup (markup) {
      this.project.content = markup
    },
    handleMarkupJSON (markup) {
      this.description = markup
    }
  },
  components: {
    Editor
  },
  mixins: [mapsMixin, breakPoints, autocomplete]
}
</script>
<style lang='scss'>
  .create-post-container {
    border-radius: 4px;
    .textarea {
      padding-left: 0;
      font-size: 2rem;
      min-height: 2rem;
      font-weight: bold;
      textarea {
        line-height: 2.5rem !important;
      }
    }
    h2 {
      letter-spacing: 2px;
      span {
        border-bottom: 3px solid ;
      }
    }
  }
  .additional {
    .check {
      border: 1px dashed var(--v-info-base);
    }
  }
  .editor-container {
    border: 1px solid var(--v-primary-base);
    border-radius: 6px;
    &.error--text {
      border: 2px solid var(--v-error-base);
    }
  }
</style>
