<template>
  <div class="d-flex justify-end main-comment">
    <v-avatar color="grey lighten-1" size="30" class="mr-3"><v-img :src="comment.avatar" :lazy-src="comment.avatar"></v-img></v-avatar>
    <v-sheet class="message px-3 py-2 rounded-lg rounded-tl-sm">
      <div class="d-flex font-weight-bold">{{ comment.name }}
        <span class="font-weight-regular caption d-flex align-end ml-1 text--disabled"> {{ comment.date | moment('from', 'now') }}</span>
      </div>
      {{comment.comment}}
    </v-sheet>
    <v-menu v-model="menu" :close-on-content-click="false" offset-y v-if="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab depressed text x-small class="align-self-center actions" v-bind="attrs" v-on="on">
          <v-icon small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-actions>
          <!--          <v-btn small text @click="menu = false">Edit</v-btn>-->
          <v-btn small color="error" text @click="deleteComment">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      default: () => {}
    },
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    dark () {
      return this.$vuetify.theme.dark
    },
    isMe () {
      return this.comment.user_id === this.currentUser.ID
    }
  },
  methods: {
    deleteComment () {
      this.$emit('deleteComment', this.comment.id)
      this.menu = false
    }
  }
}
</script>
<style scoped lang="scss">
  .actions {
    opacity: 0;
  }
  .main-comment {
    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }
  .message {
    word-break: break-word;
    background-color: rgba(0, 0, 0, 0.06);
  }
  .theme--dark {
    .message {
      background-color: rgba(255, 255, 255, 0.06);
    }
  }
</style>
