<template>
  <v-container fluid class="pa-sm-5 px-1 mobile-height">
    <div v-if="$route.name === 'project'">
      <skeleton v-show="$apollo.queries.project.loading" />
      <project-component :project="project" v-if="project && !editMode && !$apollo.queries.project.loading && !projectDeleted" @commentFocus="commentFocus = !commentFocus" :current-user="currentUser"  @editClicked="handleEditClicked" :apply="apply" @projectDeleted="handleDeletedProject"/>
      <div>
        <edit :project="project" v-if="project && editMode" @updateDone="handleUpdateDone"/>
        <comments v-if="!editMode && !error" :current-user="currentUser" :loading="$apollo.queries.project.loading" :comment-focus="commentFocus" :threadId="this.id"/>
        <div class="mt-16" v-if="project && project.isMine"></div>
      </div>
      <not-found v-if="error"/>
    </div>
  </v-container>
</template>

<script>
import ProjectComponent from '../components/ProjectComponent'
import NotFound from '../components/ProjectComponent/NotFound'
import Edit from '../components/ProjectComponent/Edit'
import Comments from '../components/ProjectComponent/Comments'
import Skeleton from '../components/Utils/Skeleton'
import PROJECT from '../graphql/singleProject.graphql'
export default {
  metaInfo () {
    return {
      title: this.project && this.project.title,
      titleTemplate: '%s Project | Gradbee',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.project && this.project.excerpt
      }, {
        vmid: 'keywords',
        name: 'keywords',
        content: this.keywords
      }]
    }
  },
  props: {
    id: {
      default: null
    },
    currentUser: {
      type: Object,
      default: null
    }
  },
  apollo: {
    project: {
      query: PROJECT,
      fetchPolicy: 'network-only',
      variables () {
        return {
          id: this.id
        }
      },
      error (err) {
        this.error = err
      }
    }
  },
  data () {
    return {
      commentFocus: false,
      editMode: false,
      apply: false,
      error: null,
      projectDeleted: false
    }
  },
  computed: {
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    },
    keywords () {
      return this.project && this.project.skills.map(item => item.name).toString()
    }
  },
  mounted () {
    let apply = this.$route.query.apply && (this.$route.query.apply).toString()
    if (apply === 'true') {
      apply = true
      this.$router.replace(this.$route.path)
    }
    if (apply === 'false') {
      apply = false
      this.$router.replace(this.$route.path)
    }
    this.apply = apply
  },
  methods: {
    handleEditClicked () {
      this.editMode = true
    },
    handleUpdateDone () {
      this.editMode = false
      this.$apollo.queries.project.refetch()
    },
    preventNav (event) {
      if (!this.editMode) return
      event.preventDefault()
      event.returnValue = ''
    },
    handleDeletedProject () {
      this.projectDeleted = true
      this.$apollo.queries.project.refetch()
    }
  },
  components: {
    ProjectComponent,
    Skeleton,
    Edit,
    Comments,
    NotFound
  },
  beforeRouteLeave (to, from, next) {
    if (this.editMode) {
      this.editMode = false
      next(false)
      return
    }
    next()
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav)
    })
  }
}
</script>

<style scoped>

</style>
