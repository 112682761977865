<template>
  <v-card>
    <v-card-text v-if="loading">
      <v-skeleton-loader class="mb-5" type="list-item-avatar-three-line" v-for="i in 2" :key="i"></v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else>
      <div class="d-flex align-start">
        <v-avatar size="40" v-if="currentUser && currentUser.meta" class="mr-3 flex-grow-0" >
          <v-img :lazy-src="currentUser.meta.et_avatar_url" :src="currentUser.meta.et_avatar_url" ></v-img>
        </v-avatar>
        <v-avatar size="40" v-else class="mr-3 flex-grow-0" color="secondary"></v-avatar>
        <div class="flex-grow-1">
          <v-form ref="commentForm" v-model="valid" @submit.prevent="validate()" id="register-form" class="v-text-field--rounded form">
            <v-sheet class="d-flex flex-column">
              <v-textarea persistent-hint hint="Please Login to comment" :disabled="isGuest" v-if="isGuest" rounded append-icon="mdi-send" filled dense color="primary" rows="1" placeholder="Add Comment" no-resize></v-textarea>
              <v-textarea persistent-hint hint="Please confirm your email to perform this action" :disabled="!registerStatus" v-else-if="!registerStatus" rounded append-icon="mdi-send" filled dense color="primary" rows="1" placeholder="Add Comment" no-resize></v-textarea>
              <v-textarea v-else counter rounded :rules="commentRules" append-icon="mdi-send" filled dense color="primary" @click:append="validate" v-model.trim="comment" rows="1" placeholder="Add Comment" no-resize auto-grow ref="comment"></v-textarea>
            </v-sheet>
          </v-form>
        </div>
      </div>
      <div class="mb-0">
        <v-list v-if="comments && comments.length > 0">
          <v-list-item v-for="(comment, i) in comments" :key="i" class="comment pl-0">
            <comment :comment="comment" :current-user="currentUser" @deleteComment="handleDelete"/>
          </v-list-item>
        </v-list>
        <div v-else-if="!isGuest" class="mt-2">
          <v-alert text color="grey" width="100%" class="mb-0">No Messages Yet</v-alert>
        </div>
        <div v-if="isGuest" class="mt-5">
          <v-btn color="secondary" depressed x-large block :to="{name: 'login'}" class="mb-0">Please Login to comment</v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Comment from './Comment'
import COMMENTS from '../../graphql/queries/comments.graphql'
const ADD_COMMENT = require('../../graphql/mutations/addComment.graphql')
const DELETE_COMMENT = require('../../graphql/mutations/deleteComment.graphql')
export default {
  apollo: {
    comments: {
      query: COMMENTS,
      variables () {
        return {
          threadId: this.threadId
        }
      },
      skip () {
        return this.isGuest
      }
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    commentFocus: {
      type: Boolean,
      default: false
    },
    threadId: {}
  },
  data () {
    return {
      comment: '',
      commentRules: [v => v.length < 281 || '280 characters limit'],
      commentLoading: false,
      valid: true
    }
  },
  computed: {
    registerStatus () {
      return this.currentUser && this.currentUser.meta && this.currentUser.meta.register_status === 'confirm'
    },
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  },
  methods: {
    validate () {
      if (this.comment && this.valid) {
        this.$refs.comment.blur()
        this.submitComment()
      }
    },
    async submitComment () {
      this.commentLoading = true
      this.comments.unshift({
        comment: this.comment,
        date: 'now',
        user_id: this.currentUser.ID,
        avatar: this.currentUser.meta.et_avatar_url
      })
      try {
        const comment = this.comment
        this.comment = ''
        await this.$apollo.mutate({
          mutation: ADD_COMMENT,
          variables: {
            threadId: this.threadId,
            message: comment
          },
          update: async (store, { data: { addComment } }) => {
            // should not do this!
            try {
              const cacheData = await store.readQuery({
                query: COMMENTS,
                variables: {
                  threadId: this.threadId
                }
              })
              const data = await cacheData
              await data.comments.shift()
              await data.comments.unshift(addComment)
              await store.writeQuery({
                query: COMMENTS,
                data
              })
            } catch (e) {
              console.log(e)
              return e
            } finally {
              // this.$emit('onStepChange', 1)
              // await this.$router.push('feed')
            }
            this.loading = false
          }
        })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    },
    async handleDelete (id) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_COMMENT,
          variables: {
            commentId: id
          },
          update: async (store, { data: { deleteComment } }) => {
            // should not do this!
            try {
              const cacheData = await store.readQuery({
                query: COMMENTS,
                variables: {
                  threadId: this.threadId
                }
              })
              await cacheData
              // await store.writeQuery({
              //   query: COMMENTS,
              //   data
              // })
            } catch (e) {
              console.log(e)
              return e
            } finally {
              // this.$emit('onStepChange', 1)
              // await this.$router.push('feed')
            }
            this.loading = false
          }
        })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    Comment
  },
  watch: {
    commentFocus () {
      this.$refs.comment.focus()
    }
  }
}
</script>

<style scoped lang="scss">
  .sub-comments {
    margin: 1rem 0 2rem 3rem;
  }
  .comment:not(:last-child) {
    margin-bottom: 1rem;
  }
</style>
