<template>
  <v-hover class="mb-5" v-slot:default="{ hover }">
    <v-card :elevation="hover ? 4 : 1">
      <v-footer class="py-5" :fixed="$vuetify.breakpoint.smAndDown" v-if="project.isMine || currentUser.role === 'administrator'">
        <v-btn x-large block color="secondary" :to="{name: 'projectInterested', params: { slug: project.name,  id: project.id } }">See Interested Applicants <v-icon small>mdi-arrow-right</v-icon></v-btn>
      </v-footer>
      <v-list-item exact :three-line="!!project.jobLocation">
        <v-list-item-avatar class="cursor-pointer" size="40" v-html="project.avatar" @click="$router.push({ name: 'profile', params: { slug: project.authorName, id: project.author }})"/>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            <span class="cursor-pointer" @click="$router.push({ name: 'profile', params: { slug: project.authorName, id: project.author }})">
            {{ project.authorName }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="d-flex caption text--secondary"><v-icon disabled x-small style="margin-left: -1px;" class="mr-1">mdi-clock</v-icon> {{ project.date | moment('from', 'now') }}</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex caption" v-if="project.jobLocation"><v-icon disabled x-small style="margin-left: -1px;" class="mr-1">mdi-map-marker</v-icon>{{ project.jobLocation}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn :loading="loading" @click.prevent="toggleBookmark('delete')" fab depressed text class="align-self-center actions" v-if="bookmarked" color="blue">
          <v-icon>mdi-bookmark</v-icon>
        </v-btn>
        <v-btn :loading="loading" @click.prevent="toggleBookmark('add')" fab depressed text class="align-self-center actions text--secondary" v-else>
          <v-icon>mdi-bookmark-outline</v-icon>
        </v-btn>
        <v-menu v-model="menu" :close-on-content-click="false" offset-y v-if="project.isMine || currentUser.role === 'administrator'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click.prevent fab depressed text class="align-self-center actions" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-actions class="d-flex flex-column ">
              <v-btn text @click="$emit('editClicked')">Edit</v-btn>
              <v-btn color="error" text @click.prevent="deleteProject" :disabled="deleteLoading">
                <v-progress-circular v-if="deleteLoading" indeterminate size="20" color="error"></v-progress-circular>
                <span v-else>Delete</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-list-item>
      <v-card-title class="py-2 text-break">{{ project.title }}</v-card-title>

      <v-card-text>
        <vue-markdown :source="project.content"></vue-markdown>
        <v-chip small label class="skills mr-3 mt-3" v-for="(skill, i) in project.skills" :key="i">#{{ skill.name }}</v-chip>
        <div class="post-meta mt-6">
          <span><v-icon small color="pink">mdi-heart</v-icon>{{ project.counts.interested }}</span>
          <span><v-icon small color="green">mdi-comment-multiple</v-icon>{{ project.counts.comments }}</span>
          <span><v-icon small color="blue">mdi-bookmark</v-icon>{{ project.counts.bookmarks }}</span>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between">
        <interested :postId="project.id" :name="project.authorName" :myBid="project.myBid" :apply="apply" :key="apply" @refetchProject="$emit('refetchProject')" :registerStatus="registerStatus" />
        <v-btn text @click="$emit('commentFocus')" class="text--secondary">
          <v-icon>mdi-comment-multiple-outline</v-icon><span class="ml-2" v-if="!mdScreen">Comment</span>
        </v-btn>
        <v-btn text class="text--secondary">
          <v-icon>mdi-share-outline</v-icon><span class="ml-2" v-if="!mdScreen">Share</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
// import GET_BID from '../../graphql/queries/getBid.graphql'
import ADD_BOOKMARK from '../../graphql/mutations/addBookmark.graphql'
import DELETE_PROJECT from '../../graphql/mutations/deleteProject.graphql'
import DELETE_BOOKMARK from '../../graphql/mutations/deleteBookmark.graphql'
import PROJECT from '../../graphql/singleProject.graphql'
import breakPoints from '../../mixins/breakPoints'
import Interested from '../Interested'
export default {
  // apollo: {
  //   bid: {
  //     query: GET_BID,
  //     variables () {
  //       bidId:
  //     }
  //   }
  // },
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    currentUser: {
      type: Object,
      default: null
    },
    apply: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      loading: false,
      deleteLoading: false
    }
  },
  computed: {
    registerStatus () {
      return this.currentUser && this.currentUser.meta && this.currentUser.meta.register_status === 'confirm'
    },
    bookmarked () {
      return this.project && this.project.isBookmarked
    }
  },
  methods: {
    async toggleBookmark (type) {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: type === 'add' ? ADD_BOOKMARK : DELETE_BOOKMARK,
          variables: {
            id: type === 'add' ? this.project.id : this.project.isBookmarked
          },
          update: (store, { data: { addBookmark } }) => {
            const query = {
              query: PROJECT,
              variables: {
                id: this.project.id
              }
            }
            const data = store.readQuery(query)
            if (type === 'add') {
              data.project.isBookmarked = addBookmark
              data.project.counts.bookmarks++
            } else {
              data.project.isBookmarked = null
              data.project.counts.bookmarks--
            }
            store.writeQuery({
              ...query,
              data
            })
          }
        })
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    async deleteProject () {
      try {
        this.deleteLoading = true
        await this.$apollo.mutate({
          mutation: DELETE_PROJECT,
          variables: {
            id: this.project.id
          }
        })
        this.$emit('refetchProject')
        this.$emit('projectDeleted')
      } catch (e) {
        return e
      } finally {
        this.deleteLoading = false
      }
    }
  },
  components: {
    Interested
  },
  mixins: [breakPoints]
}
</script>
<style lang="scss" scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
