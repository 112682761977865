<template>
  <v-dialog v-model="dialog" max-width="600" eager overlay-opacity="0.9">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="!isLoggedIn">
        <v-btn text class="text--secondary" :to="{name: 'login'}">
          <v-icon>mdi-lock</v-icon><span class="ml-2" v-if="!$vuetify.breakpoint.smAndDown">Interested</span>
        </v-btn>
      </div>
      <div v-else>
        <v-btn text v-if="myBid" color="success" @click.prevent v-bind="attrs" v-on="on" small>
          <v-icon color="success">mdi-check</v-icon>
          <span class="ml-2">{{ myBid.date | moment('from', 'now') }}</span>
        </v-btn>
        <v-btn text v-bind="attrs" v-on="on" v-else @click.prevent class="text--secondary">
          <v-icon>mdi-heart-outline</v-icon><span class="ml-2" v-if="!$vuetify.breakpoint.smAndDown">Interested</span>
        </v-btn>
      </div>
    </template>

    <v-card :loading="loading" v-if="!myBid">
      <v-card-title primary-title>
        You can a small note to {{ name }}!
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form ref="interestedForm" v-model="valid" @submit.prevent="validate" :id="`interested-form-${postId}`" class="v-text-field--rounded form">
          <v-textarea counter v-model="message" persistent-hint hint="Increase your chances by adding a note!" outlined :rules="messageRules" label="Write your note here" no-resize class="mb-5"></v-textarea>
          <div v-if="!registerStatus" class="mb-3 error--text">Please confirm your email to perform this action.</div>
          <v-btn @click.prevent="validate" block color="secondary" large :disabled="!valid || loading || !registerStatus">
            Submit your interest <v-icon small>mdi-arrow-right</v-icon>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title primary-title>
        <v-icon color="success" class="mr-1">mdi-check</v-icon> Applied {{ myBid.date | moment('from', 'now') }}!
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import ADD_BID from '../graphql/mutations/addBid.graphql'
export default {
  props: {
    postId: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    myBid: {
      type: Object,
      default: null
    },
    apply: {
      type: Boolean,
      default: false
    },
    registerStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: this.apply,
      loading: false,
      valid: true,
      message: '',
      messageRules: [
        v => v.length < 281 || '280 characters limit'
      ]
    }
  },
  computed: {
    isLoggedIn () {
      return localStorage.getItem('apollo-token')
    }
  },
  methods: {
    validate () {
      if (this.$refs.interestedForm.validate()) {
        this.submitInterested()
      }
    },
    async submitInterested () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: ADD_BID,
          variables: {
            postId: this.postId,
            message: this.message
          }
        })
        this.dialog = false
        this.message = ''
        this.$emit('refetchProject')
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
