<template>
  <div class="d-flex flex-column align-center">
    <h3 class="text-center mb-5">😐 Sorry, The project is either deleted or doesn't exist.</h3>
    <v-avatar size="500" max-width="100%" rounded>
      <v-img :src="img" v-if="img"/>
    </v-avatar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      img: null
    }
  },
  async mounted () {
    await this.giphy()
  },
  methods: {
    async giphy () {
      const res = await fetch('https://api.giphy.com/v1/gifs/random?api_key=8gDDorqnh7aj4JpcfJsKDtEAswqB4ABA&tag=sorry&rating=r')
      const data = await res.json()
      this.img = data.data.images.downsized_medium.url
    }
  }
}
</script>

<style scoped>

</style>
